import { useAppContext } from '../../../hooks/useAppContext';
import { StyledLayout } from '@/components/Layouts/App/styles';
import { ILayout } from '@/components/Layouts/types';
import { ThemeProvider } from 'styled-components';

export const AppLayout = ({ dataTestId, children }: ILayout) => {
  const { theme } = useAppContext();
  return (
    <ThemeProvider theme={theme}>
      <StyledLayout data-testid={dataTestId}>{children}</StyledLayout>
    </ThemeProvider>
  );
};
