import styled from 'styled-components';
import { palette } from '../../colors';
import { px2Rem } from '../../utils/px2Rem';

const StyledTextAccordion = styled.div`
  background-color: ${palette.white};
  ul {
    padding: 0;
    margin: 0;
  }
`;

const StyledTextAccordionItem = styled.li<{
  active: boolean;
  $headerBackgroundHoverColor?: string;
  $showDivider?: boolean;
  $isSubMenu?: boolean;
}>`
  ${({ $headerBackgroundHoverColor, active, $showDivider, $isSubMenu }) => `
    list-style: none;
    padding-bottom: ${px2Rem(active ? 32 : 16)};
    transition: padding-bottom 0.5s ease;
    cursor: pointer;
    margin: 0!important;

    &:last-of-type {
      padding-bottom: 0;
    }

    .item-header {
      display: flex;
      align-items: center;
      flex-direction: row;
      padding: ${$isSubMenu ? `${px2Rem(26)} ${px2Rem(24)}` : `0 0 ${$showDivider ? px2Rem(26) : 0} 0`};

      ${
        $headerBackgroundHoverColor &&
        `
        &:hover {
          background-color: ${$headerBackgroundHoverColor};
        }
      `
      }
    }

    .item-body {
      display: grid;
      grid-template-rows: ${active ? '1fr' : '0fr'};
      color: ${palette.charcoal[500]};
      padding-top: ${$isSubMenu ? '0' : '1rem'};
      transition: grid-template-rows 0.4s ease-out;
      border-bottom: ${$showDivider ? `1px solid ${palette.charcoal[200]}` : 'none'};

      a {
        text-decoration: none;

        &:hover {
          text-decoration: none!important;
      }
    }
  `}
`;

export { StyledTextAccordion, StyledTextAccordionItem };
