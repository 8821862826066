import { palette } from '@raylo-tech/raylopay-ui';

/** Use the uibook palette as a base and add any additional brand colours where needed */
export const affordableMobilesPalette = {
  ...palette,
  brand: {
    orange: '#ff6900',
    purple: '#4a25aa',
  },
} as const;

export const rayloPayColors = {
  lightBlue: '#6c7efd',
};
