import Typography from '../../../foundations/Typography';
import {
  StyledNavBarConsumerToggle,
  StyledNavBarConsumerToggleItem,
  StyledNavBarConsumerToggleAnchor,
} from './NavBarConsumerToggle.styles';
import { useNavBarContext } from '../NavBarContext/NavBarContext';
import { ConsumerTypesEnum } from '../../../types/consumerTypes.types';

type NavBarConsumerToggleProps = {
  viewport: 'mobile' | 'desktop';
};

export const NavBarConsumerToggle = ({ viewport }: NavBarConsumerToggleProps) => {
  const { LinkComponent, consumerType, toggleUrl, handleCloseSideNav, showConsumerToggle } =
    useNavBarContext();

  if (!showConsumerToggle) {
    return null;
  }

  const handleClick = () => {
    /**
     * Wait for 300ms so the user gets to see the toggle effect, whilst the content behind the nav
     * changes
     */
    setTimeout(() => {
      handleCloseSideNav();
    }, 300);
  };

  const items = [
    {
      label: 'Personal',
      type: ConsumerTypesEnum.PERSONAL,
      isCurrent: consumerType === ConsumerTypesEnum.PERSONAL,
    },
    {
      label: 'Business',
      type: ConsumerTypesEnum.BUSINESS,
      isCurrent: consumerType === ConsumerTypesEnum.BUSINESS,
    },
  ];

  const currentItemIndex = items.findIndex((item) => item.isCurrent);

  return (
    <StyledNavBarConsumerToggle $viewport={viewport} data-testid="nav-bar-consumer-toggle">
      <StyledNavBarConsumerToggleAnchor
        $currentItemIndex={currentItemIndex}
        as={LinkComponent}
        href={toggleUrl}
        onClick={handleClick}
        className="consumer-toggle-anchor"
        data-testid="nav-bar-consumer-toggle-anchor"
      >
        {items.map((item) => (
          <StyledNavBarConsumerToggleItem
            key={item.label}
            $isCurrent={item.isCurrent}
            className={item.isCurrent ? 'is-current' : undefined}
            data-testid={`nav-bar-consumer-toggle-${item.type}`}
          >
            <Typography element="span" variant={viewport === 'desktop' ? 'body2' : 'body1'}>
              {item.label}
            </Typography>
          </StyledNavBarConsumerToggleItem>
        ))}
      </StyledNavBarConsumerToggleAnchor>
    </StyledNavBarConsumerToggle>
  );
};
