import { StyledCopy } from './styles';
import { ICopy } from './types';

/**
 * @deprecated This component has been replaced with the `<Typography />` component in
 *   `@raylo-tech/raylopay-ui`.
 */
const Copy = ({
  children,
  color,
  fontSize,
  dataTestId,
  bold,
  medium,
  italic,
  lineHeight,
  letterSpacing,
  center,
  textAlign,
  textTransform,
  textWrap,
}: ICopy) => {
  return (
    <StyledCopy
      color={color}
      fontSize={fontSize}
      data-testid={dataTestId}
      bold={bold}
      medium={medium}
      italic={italic}
      lineHeight={lineHeight}
      letterSpacing={letterSpacing}
      center={center}
      textAlign={textAlign}
      textTransform={textTransform}
      textWrap={textWrap}
    >
      {children}
    </StyledCopy>
  );
};

export default Copy;
