import { palette } from '../../../colors';
import { px2Rem } from '../../../utils/px2Rem';
import { ASSETS_SVGS_BASE_URL, CSS_FONT_WEIGHT } from '../../../constants';
import styled from 'styled-components';

export const StyledSideNavSubMenuWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`;

export const StyledSideNavSubMenu = styled.ul<{
  $active: boolean;
  $zIndex: number;
  $isGoingBack: boolean;
}>`
  ${({ $active, $zIndex, $isGoingBack }) => `
    transform: ${$active ? 'translateX(0)' : `translateX(${$isGoingBack ? '-100%' : '100%'})`};
    height: ${$active ? '100%' : '0'};
    visibility: ${$active ? 'visible' : 'hidden'};
    overflow: ${$active ? 'visible' : 'hidden'};
    transition: transform 0.3s ease;
    animation-fill-mode: backwards;
    padding: 0;
    margin: 0;
    list-style: none;
    cursor: pointer;
    grid-row-start: 1;
    grid-column-start: 1;
    z-index: ${$zIndex};
    width: 100%;

    li {
      width: 100%;
    }

    .menu-item {
      margin: 0!important;
      background-color: transparent;
      transition: background-color 0.3s ease;

      a {
        text-decoration: none!important;
      }
    }

    .menu-item:hover, .item-body a:hover {
      background-color: ${palette.charcoal[100]};
    }

    .menu-item {
      margin: 0!important;
      svg {
        margin-right: ${px2Rem(14.5)};
        circle {
          fill: ${palette.charcoal[100]};
          transition: fill 0.3s ease;
        }
      }
      &:hover {
        svg circle {
          fill: ${palette.charcoal[200]};
        }
      }

      a {
        text-decoration: none!important;
      }
    }
  `};
`;

export const StyledMenuItem = styled.button<{
  $icon?: string;
  $staticIcon?: string;
  $iconSize?: number;
  $isBold?: boolean;
  $hideChevron?: boolean;
  $border?: boolean;
}>`
  ${({ $icon, $staticIcon, $isBold, $hideChevron, $border, $iconSize }) => `
    display: flex;
    border: none;
    background: transparent;
    color: ${palette.charcoal[500]};
    padding: 0;
    font-weight: ${$isBold ? CSS_FONT_WEIGHT.vars.bold : CSS_FONT_WEIGHT.vars.regular};
    font-size: ${px2Rem(16)};
    line-height: ${px2Rem(19)};
    width: calc(100% - ${px2Rem(24)});
    cursor: pointer;
    text-align: left;
    padding: ${$icon ? `${px2Rem(12)} ${px2Rem(24)} ${px2Rem(12)} ${px2Rem(14)}` : `${px2Rem(24)}`};
    align-items: center;
    text-decoration: none;

    ${
      !$hideChevron &&
      `
      background-image: url("${ASSETS_SVGS_BASE_URL}/chevron-right-black.svg");
      background-repeat: no-repeat;
      background-size: ${px2Rem(16)};
      background-position: 100% 50%;
    `
    };

    ${
      $border &&
      `
      border-bottom: 1px solid ${palette.charcoal[200]};
      border-top: 1px solid ${palette.charcoal[200]};
    `
    };


    ${
      $staticIcon &&
      `
      &::before {
        content: "";
        background-image: url("${ASSETS_SVGS_BASE_URL}/${$staticIcon}.svg");
        background-repeat: no-repeat;
        background-size: ${px2Rem($iconSize ?? 48)};
        background-position: center;
        height: ${px2Rem($iconSize ?? 48)};
        width: ${px2Rem($iconSize ?? 48)};
        margin-right: ${px2Rem(16)};
      }
    `
    };
  `};
`;

export const StyledNavigateBack = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  padding: ${px2Rem(24)} ${px2Rem(64)};
  font-size: ${px2Rem(16)};
  line-height: ${px2Rem(19)};
  font-weight: ${CSS_FONT_WEIGHT.vars.bold};

  button {
    cursor: pointer;
    border: none;
    background: transparent;
    padding: 0;
    height: ${px2Rem(24)};
    width: ${px2Rem(24)};
    position: absolute;
    background-image: url('${ASSETS_SVGS_BASE_URL}/nav-arrow-back.svg');
    background-repeat: no-repeat;
    background-size: ${px2Rem(24)};
    left: ${px2Rem(24)};
  }

  p,
  a {
    text-align: center;
    text-decoration: none;
    margin: 0;
    width: 100%;
    color: ${palette.charcoal[500]}!important;
    font-weight: ${CSS_FONT_WEIGHT.vars.bold};

    &:hover {
      text-decoration: none;
    }
  }
`;

export const StyledCloseButton = styled.button`
  background: transparent;
  border: none;
  padding: 0;
  height: ${px2Rem(24)};
  width: ${px2Rem(24)};

  svg rect {
    fill: ${palette.white};
    transition: fill 0.3s ease;
  }

  &:hover {
    svg rect {
      fill: ${palette.blue[200]};
    }
  }
`;
