import { StyledDualWeightCopy } from './styles';
import { IDualWeightCopy } from './types';

export const DualWeightCopy = ({ dataTestId, children, fontSize, fontColor }: IDualWeightCopy) => {
  return (
    <StyledDualWeightCopy data-testid={dataTestId} fontSize={fontSize} fontColor={fontColor}>
      {children}
    </StyledDualWeightCopy>
  );
};
