export enum ConsumerTypesEnum {
  PERSONAL = 'Personal',
  BUSINESS = 'Business',
}

/** The `Money` field to get the price from within the `TaxableAmount` field */
export const consumerTypeMoneyField = {
  [ConsumerTypesEnum.PERSONAL]: 'valueAfterTax' as const,
  [ConsumerTypesEnum.BUSINESS]: 'valueBeforeTax' as const,
};

export type ConsumerTypeMoneyField =
  (typeof consumerTypeMoneyField)[keyof typeof consumerTypeMoneyField];
