import { px2Rem } from './../../../utils/px2Rem';
import { affordableMobilesPalette } from './../affordableMobiles/colours';
import { palette } from '@raylo-tech/raylopay-ui';

export const aboutUsPage = {
  heroSection: {
    backgroundColor: affordableMobilesPalette.brand.purple,
    copyColor: palette.white,
    button: {
      borderRadius: px2Rem(50),
      color: palette.white,
      background: affordableMobilesPalette.brand.orange,
    },
  },
  ourCustomers: {
    headerColor: affordableMobilesPalette.brand.purple,
    copyColor: palette.charcoal[500],
  },
  whyLease: {
    headerColor: affordableMobilesPalette.brand.purple,
    copyColor: palette.charcoal[500],
  },
  howItWorks: {
    stepDisplay: {
      backgroundColor: palette.blue[200],
    },
    headerColor: palette.charcoal[500],
  },
};
