import { Link } from '../Link/Link';
import { StyledTextLink } from './styles';
import { ITextLink } from './types';

export const TextLink = ({
  dataTestId,
  children,
  fontSize,
  fontColor,
  onClick,
  link,
  href,
  target,
}: ITextLink) => {
  if (link) {
    return (
      <Link href={link}>
        <StyledTextLink data-testid={dataTestId} fontSize={fontSize} fontColor={fontColor}>
          {children}
        </StyledTextLink>
      </Link>
    );
  }
  if (href) {
    return (
      <a href={href} target={target || '_blank'} rel="noopener noreferrer">
        <StyledTextLink data-testid={dataTestId} fontSize={fontSize} fontColor={fontColor}>
          {children}
        </StyledTextLink>
      </a>
    );
  }
  return (
    <StyledTextLink
      data-testid={dataTestId}
      fontSize={fontSize}
      fontColor={fontColor}
      onClick={onClick}
    >
      {children}
    </StyledTextLink>
  );
};
