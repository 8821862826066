import { consumerTypePathBase, ConsumerTypesEnum } from '@/types/consumerTypes';

/**
 * Formats the image URL by removing the query parameters related to image trimming.
 *
 * @param image - The image URL to be formatted.
 * @returns The formatted image URL.
 */
export const formatImageUrl = (image: string | undefined) => {
  return image?.replace('?trim-auto', '')?.replace('?trim=auto', '');
};

/**
 * Prefixes the URL path based on the consumer type.
 *
 * @param consumerType - The type of consumer.
 * @param path - The URL path to be prefixed.
 * @returns The prefixed URL path.
 */
export const prefixUrlPathByConsumerType = (
  consumerType: ConsumerTypesEnum,
  path: `/${string}`,
) => {
  return `${consumerTypePathBase[consumerType]}${path}`;
};
