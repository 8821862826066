import { CSS_FONT_WEIGHT } from '@raylo-tech/raylopay-ui';
import { px2Rem } from './../../utils/px2Rem';
import { IDualWeightCopy } from './types';
import styled from 'styled-components';

const StyledDualWeightCopy = styled.p<IDualWeightCopy>`
  ${({ fontSize }) => fontSize && `font-size: ${px2Rem(fontSize)};`} /* span {
    font-weight: ${CSS_FONT_WEIGHT.vars.bold};
  } */
  ${({ fontColor }) => `color: ${fontColor};`}
`;

export { StyledDualWeightCopy };
