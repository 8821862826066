import styled from 'styled-components';
import { TransientTypographyProps, TypographyProps } from './types';
import React from 'react';
import { px2Rem } from '../../utils/px2Rem';
import { CSS_FONT_WEIGHT } from '../../constants';
import { getPaletteColorByDotNotation } from '../../colors';

type TypographyVariantStyle = {
  fontSize: React.CSSProperties['fontSize'];
  lineHeight: React.CSSProperties['lineHeight'];
};

const defaultVariant = 'body1';

const lineHeightToMaxThreeDecimals = (lineHeight: number) => parseFloat(lineHeight.toFixed(3));

export const typographyVariants: Record<
  NonNullable<TypographyProps['variant']>,
  TypographyVariantStyle
> = {
  h1: {
    fontSize: px2Rem(36),
    lineHeight: lineHeightToMaxThreeDecimals(43.2 / 36),
  },
  h2: {
    fontSize: px2Rem(24),
    lineHeight: lineHeightToMaxThreeDecimals(28.8 / 24),
  },
  h3: {
    fontSize: px2Rem(20),
    lineHeight: lineHeightToMaxThreeDecimals(24 / 20),
  },
  h4: {
    fontSize: px2Rem(18),
    lineHeight: lineHeightToMaxThreeDecimals(21.6 / 18),
  },
  body1: {
    fontSize: px2Rem(16),
    lineHeight: lineHeightToMaxThreeDecimals(19.2 / 16),
  },
  body2: {
    fontSize: px2Rem(14),
    lineHeight: lineHeightToMaxThreeDecimals(16.8 / 14),
  },
  fineprint: {
    fontSize: px2Rem(12),
    lineHeight: lineHeightToMaxThreeDecimals(14.4 / 12),
  },
};

export const StyledTypography = styled.p<TransientTypographyProps>`
  font-size: ${({ $variant }) => typographyVariants[$variant ?? defaultVariant].fontSize};
  line-height: ${({ $variant }) => typographyVariants[$variant ?? defaultVariant].lineHeight};
  font-weight: ${({ $bold, $medium }) =>
    $bold
      ? CSS_FONT_WEIGHT.vars.bold
      : $medium
        ? CSS_FONT_WEIGHT.vars.medium
        : CSS_FONT_WEIGHT.vars.regular};
  ${({ $italic }) => $italic && 'font-style: italic;'}
  ${({ $color }) => $color && `color: ${getPaletteColorByDotNotation($color)};`}
`;
