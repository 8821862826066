import styled from 'styled-components';
import { px2Rem } from '../../utils/px2Rem';
import { ASSETS_SVGS_BASE_URL, BREAKPOINTS, CSS_FONT_WEIGHT } from '../../constants';
import { palette } from '../../colors';

export const StyledCalloutCarouselWrapper = styled.div`
  background-color: ${palette.white};
  display: flex;
  justify-content: center;

  .carousel-button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: ${px2Rem(BREAKPOINTS.smallDesktop)};

    &:hover .slide-button {
      opacity: 1;
    }

    .slide-button {
      border: none;
      background-color: transparent;
      background-size: ${px2Rem(16)} ${px2Rem(16)};
      background-repeat: no-repeat;
      margin: 0;
      padding: 0;
      cursor: pointer;
      height: ${px2Rem(32)};
      width: ${px2Rem(32)};
      opacity: 0;
      transition: opacity 0.4s ease;

      &.prev {
        background-image: url('${ASSETS_SVGS_BASE_URL}/carousel-arrow-prev.svg');
        background-position: right center;
        margin-right: ${px2Rem(10)};
      }

      &.next {
        background-image: url('${ASSETS_SVGS_BASE_URL}/carousel-arrow-next.svg');
        background-position: left center;
        margin-left: ${px2Rem(10)};
      }
    }

    .embla {
      overflow: hidden;
      width: calc(100% - ${px2Rem(100)});
    }

    .slide-container {
      display: flex;
      flex-direction: row;

      .slide {
        min-width: 0;
        padding: ${px2Rem(10)} 0;
        flex: 0 0 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    p {
      margin: 0;
      padding: 0;
      color: ${palette.blue[500]};
      line-height: ${px2Rem(17)};
      font-size: ${px2Rem(14)};
      font-weight: ${CSS_FONT_WEIGHT.vars.medium};
    }
  }
`;
