/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 *
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
  '\n  fragment VariantCostSummaryInitialFields on PricePlanInitialCostSummary {\n    totalAmount {\n      valueAfterTax {\n        value\n      }\n      valueBeforeTax {\n        value\n      }\n    }\n  }\n\n  fragment VariantCostSummaryRecurringFields on PricePlanRecurringCostSummary {\n    totalAmount {\n      valueAfterTax {\n        value\n      }\n      valueBeforeTax {\n        value\n      }\n    }\n    insuranceAmount {\n      valueAfterTax {\n        value\n      }\n      valueBeforeTax {\n        value\n      }\n    }\n  }\n':
    types.VariantCostSummaryInitialFieldsFragmentDoc,
  '\n  query ProductsRepoCustomerSpecificPricing($ids: [ID!], $merchantId: ID!, $checkoutToken: String) {\n    variants(forCheckout: true, ids: $ids, checkoutToken: $checkoutToken, merchantId: $merchantId) {\n      id\n      costSummaries(merchantId: $merchantId) {\n        id\n        recurring {\n          ...VariantCostSummaryRecurringFields\n        }\n      }\n    }\n  }\n':
    types.ProductsRepoCustomerSpecificPricingDocument,
  '\n  query ProductsRepoCustomerSpecificLowestCostSummary($ids: [ID!], $merchantId: ID!, $checkoutToken: String) {\n    variants(forCheckout: true, ids: $ids, checkoutToken: $checkoutToken, merchantId: $merchantId) {\n      id\n      lowestCostSummary(merchantId: $merchantId) {\n        id\n        recurring {\n          ...VariantCostSummaryRecurringFields\n        }\n      }\n    }\n  }\n':
    types.ProductsRepoCustomerSpecificLowestCostSummaryDocument,
  '\n  query ProductsRepoGetDeliveryDates($ids: [ID!], $merchantId: ID!) {\n    variants(forCheckout: true, ids: $ids, merchantId: $merchantId) {\n      id\n      available(merchantId: $merchantId)\n      deliveryExpectedBetween(merchantId: $merchantId) {\n        max\n        min\n      }\n    }\n  }\n':
    types.ProductsRepoGetDeliveryDatesDocument,
  '\n  query AccountGetPreApprovalDataWithTaxQuery {\n  customer {\n    id\n    preApproval {\n      recurringTaxableAmount {\n        valueAfterTax {\n          formattedValue\n          value\n        }\n        valueBeforeTax {\n          formattedValue\n          value\n        }\n      }\n      successful\n    }\n  }\n}':
    types.AccountGetPreApprovalDataWithTaxQueryDocument,
  '\n  query ProductsRepoGetProductVariantSlugs($merchantId: ID!) {\n    products(forCheckoutInput: { onlyAvailable: false }, merchantId: $merchantId) {\n      id\n      variants(forCheckoutInput: { onlyAvailable: false }, merchantId: $merchantId) {\n        id\n        slug\n        position\n        available(merchantId: $merchantId)\n      }\n      lowestCostSummary(merchantId: $merchantId) {\n        id\n        termLength\n      }\n    }\n  }\n':
    types.ProductsRepoGetProductVariantSlugsDocument,
  '\n  query ProductsRepoGetPaginatedProductDetails($merchantId: ID!, $first: Int, $after: String) {\n    productsConnection(forCheckoutInput: { onlyAvailable: false }, merchantId: $merchantId, first: $first, after: $after) {\n      nodes {\n        available\n        id\n        displayName\n        variantConditions\n        position\n        manufacturer {\n          id\n          displayName\n        }\n        category {\n          id\n          displayName\n        }\n        variants(forCheckoutInput: { onlyAvailable: false }, merchantId: $merchantId) {\n          id\n          images {\n            url\n          }\n          displayName\n          available(merchantId: $merchantId)\n          condition\n          position\n          slug\n          optionValues {\n            raw\n            displayName\n            optionType {\n              slug\n            }\n          }\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n':
    types.ProductsRepoGetPaginatedProductDetailsDocument,
  '\n  query ProductsRepoGetPaginatedProductLowestCostSummaries($merchantId: ID!, $first: Int, $after: String) {\n    productsConnection(forCheckoutInput: { onlyAvailable: false }, merchantId: $merchantId, first: $first, after: $after) {\n      nodes {\n        id\n        lowestCostSummary(merchantId: $merchantId) {\n          id\n          termLength\n          recurring {\n            ...VariantCostSummaryRecurringFields\n          }\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n':
    types.ProductsRepoGetPaginatedProductLowestCostSummariesDocument,
  '\n  query AccountIntercomQuery {\n  customer {\n    id\n    email\n    messagingDetails {\n      intercom {\n        appId\n        userHash\n        userId\n      }\n    }\n  }\n}':
    types.AccountIntercomQueryDocument,
  '\n  query ProductsRepoProductCategories($merchantId: ID!) {\n    productCategories(merchantId: $merchantId) {\n      id\n      slug\n      displayName\n    }\n  }\n':
    types.ProductsRepoProductCategoriesDocument,
  '\n  query ProductsRepoVariantPricePlans($ids: [ID!], $merchantId: ID!) {\n    variants(forCheckout: true, merchantId: $merchantId, ids: $ids) {\n      id\n      costSummaries(merchantId: $merchantId) {\n        id\n        includesInsurance\n        termLength\n        recurring {\n          ...VariantCostSummaryRecurringFields\n        }\n        initial {\n          ...VariantCostSummaryInitialFields\n        }\n        feeSummary {\n          nonReturnFee {\n            formattedValue\n          }\n        }\n      }\n    }\n  }\n':
    types.ProductsRepoVariantPricePlansDocument,
  '\n  query ProductsRepoProductVariantConditions($productCategoryIds: [ID!], $merchantId: ID!) {\n    products(forCheckout: true, productCategoryIds: $productCategoryIds, merchantId: $merchantId) {\n      id\n      variants(forCheckout: true, merchantId: $merchantId) {\n        id\n        condition\n      }\n    }\n  }\n':
    types.ProductsRepoProductVariantConditionsDocument,
  '\n  query ProductsRepoProductVariants($ids: [ID!], $merchantId: ID!) {\n    products(forCheckoutInput: { onlyAvailable: false }, ids: $ids, merchantId: $merchantId) {\n      id\n      canAddTradeIn\n      displayName\n      specifications\n      keyFeatures\n      optionTypes {\n        id\n        slug\n        displayName\n        position\n      }\n      category {\n        id\n        displayName\n      }\n      manufacturer {\n        id\n        displayName\n      }\n      overview\n      variants(forCheckoutInput: { onlyAvailable: false }, merchantId: $merchantId) {\n        id\n        displayName\n        slug\n        subtitle\n        specifications\n        position\n        available(merchantId: $merchantId)\n        condition\n        images {\n          alt\n          position\n          tag\n          title\n          url\n        }\n        optionValues {\n          id\n          displayName\n          raw\n          position\n          optionType {\n            id\n            slug\n          }\n        }\n      }\n    }\n  }\n':
    types.ProductsRepoProductVariantsDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL
 * clients.
 *
 * @example
 *   ```ts
 *   const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 *   ```
 *
 *   The query argument is unknown!
 *   Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL
 * clients.
 */
export function gql(
  source: '\n  fragment VariantCostSummaryInitialFields on PricePlanInitialCostSummary {\n    totalAmount {\n      valueAfterTax {\n        value\n      }\n      valueBeforeTax {\n        value\n      }\n    }\n  }\n\n  fragment VariantCostSummaryRecurringFields on PricePlanRecurringCostSummary {\n    totalAmount {\n      valueAfterTax {\n        value\n      }\n      valueBeforeTax {\n        value\n      }\n    }\n    insuranceAmount {\n      valueAfterTax {\n        value\n      }\n      valueBeforeTax {\n        value\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment VariantCostSummaryInitialFields on PricePlanInitialCostSummary {\n    totalAmount {\n      valueAfterTax {\n        value\n      }\n      valueBeforeTax {\n        value\n      }\n    }\n  }\n\n  fragment VariantCostSummaryRecurringFields on PricePlanRecurringCostSummary {\n    totalAmount {\n      valueAfterTax {\n        value\n      }\n      valueBeforeTax {\n        value\n      }\n    }\n    insuranceAmount {\n      valueAfterTax {\n        value\n      }\n      valueBeforeTax {\n        value\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL
 * clients.
 */
export function gql(
  source: '\n  query ProductsRepoCustomerSpecificPricing($ids: [ID!], $merchantId: ID!, $checkoutToken: String) {\n    variants(forCheckout: true, ids: $ids, checkoutToken: $checkoutToken, merchantId: $merchantId) {\n      id\n      costSummaries(merchantId: $merchantId) {\n        id\n        recurring {\n          ...VariantCostSummaryRecurringFields\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query ProductsRepoCustomerSpecificPricing($ids: [ID!], $merchantId: ID!, $checkoutToken: String) {\n    variants(forCheckout: true, ids: $ids, checkoutToken: $checkoutToken, merchantId: $merchantId) {\n      id\n      costSummaries(merchantId: $merchantId) {\n        id\n        recurring {\n          ...VariantCostSummaryRecurringFields\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL
 * clients.
 */
export function gql(
  source: '\n  query ProductsRepoCustomerSpecificLowestCostSummary($ids: [ID!], $merchantId: ID!, $checkoutToken: String) {\n    variants(forCheckout: true, ids: $ids, checkoutToken: $checkoutToken, merchantId: $merchantId) {\n      id\n      lowestCostSummary(merchantId: $merchantId) {\n        id\n        recurring {\n          ...VariantCostSummaryRecurringFields\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query ProductsRepoCustomerSpecificLowestCostSummary($ids: [ID!], $merchantId: ID!, $checkoutToken: String) {\n    variants(forCheckout: true, ids: $ids, checkoutToken: $checkoutToken, merchantId: $merchantId) {\n      id\n      lowestCostSummary(merchantId: $merchantId) {\n        id\n        recurring {\n          ...VariantCostSummaryRecurringFields\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL
 * clients.
 */
export function gql(
  source: '\n  query ProductsRepoGetDeliveryDates($ids: [ID!], $merchantId: ID!) {\n    variants(forCheckout: true, ids: $ids, merchantId: $merchantId) {\n      id\n      available(merchantId: $merchantId)\n      deliveryExpectedBetween(merchantId: $merchantId) {\n        max\n        min\n      }\n    }\n  }\n',
): (typeof documents)['\n  query ProductsRepoGetDeliveryDates($ids: [ID!], $merchantId: ID!) {\n    variants(forCheckout: true, ids: $ids, merchantId: $merchantId) {\n      id\n      available(merchantId: $merchantId)\n      deliveryExpectedBetween(merchantId: $merchantId) {\n        max\n        min\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL
 * clients.
 */
export function gql(
  source: '\n  query AccountGetPreApprovalDataWithTaxQuery {\n  customer {\n    id\n    preApproval {\n      recurringTaxableAmount {\n        valueAfterTax {\n          formattedValue\n          value\n        }\n        valueBeforeTax {\n          formattedValue\n          value\n        }\n      }\n      successful\n    }\n  }\n}',
): (typeof documents)['\n  query AccountGetPreApprovalDataWithTaxQuery {\n  customer {\n    id\n    preApproval {\n      recurringTaxableAmount {\n        valueAfterTax {\n          formattedValue\n          value\n        }\n        valueBeforeTax {\n          formattedValue\n          value\n        }\n      }\n      successful\n    }\n  }\n}'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL
 * clients.
 */
export function gql(
  source: '\n  query ProductsRepoGetProductVariantSlugs($merchantId: ID!) {\n    products(forCheckoutInput: { onlyAvailable: false }, merchantId: $merchantId) {\n      id\n      variants(forCheckoutInput: { onlyAvailable: false }, merchantId: $merchantId) {\n        id\n        slug\n        position\n        available(merchantId: $merchantId)\n      }\n      lowestCostSummary(merchantId: $merchantId) {\n        id\n        termLength\n      }\n    }\n  }\n',
): (typeof documents)['\n  query ProductsRepoGetProductVariantSlugs($merchantId: ID!) {\n    products(forCheckoutInput: { onlyAvailable: false }, merchantId: $merchantId) {\n      id\n      variants(forCheckoutInput: { onlyAvailable: false }, merchantId: $merchantId) {\n        id\n        slug\n        position\n        available(merchantId: $merchantId)\n      }\n      lowestCostSummary(merchantId: $merchantId) {\n        id\n        termLength\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL
 * clients.
 */
export function gql(
  source: '\n  query ProductsRepoGetPaginatedProductDetails($merchantId: ID!, $first: Int, $after: String) {\n    productsConnection(forCheckoutInput: { onlyAvailable: false }, merchantId: $merchantId, first: $first, after: $after) {\n      nodes {\n        available\n        id\n        displayName\n        variantConditions\n        position\n        manufacturer {\n          id\n          displayName\n        }\n        category {\n          id\n          displayName\n        }\n        variants(forCheckoutInput: { onlyAvailable: false }, merchantId: $merchantId) {\n          id\n          images {\n            url\n          }\n          displayName\n          available(merchantId: $merchantId)\n          condition\n          position\n          slug\n          optionValues {\n            raw\n            displayName\n            optionType {\n              slug\n            }\n          }\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n',
): (typeof documents)['\n  query ProductsRepoGetPaginatedProductDetails($merchantId: ID!, $first: Int, $after: String) {\n    productsConnection(forCheckoutInput: { onlyAvailable: false }, merchantId: $merchantId, first: $first, after: $after) {\n      nodes {\n        available\n        id\n        displayName\n        variantConditions\n        position\n        manufacturer {\n          id\n          displayName\n        }\n        category {\n          id\n          displayName\n        }\n        variants(forCheckoutInput: { onlyAvailable: false }, merchantId: $merchantId) {\n          id\n          images {\n            url\n          }\n          displayName\n          available(merchantId: $merchantId)\n          condition\n          position\n          slug\n          optionValues {\n            raw\n            displayName\n            optionType {\n              slug\n            }\n          }\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL
 * clients.
 */
export function gql(
  source: '\n  query ProductsRepoGetPaginatedProductLowestCostSummaries($merchantId: ID!, $first: Int, $after: String) {\n    productsConnection(forCheckoutInput: { onlyAvailable: false }, merchantId: $merchantId, first: $first, after: $after) {\n      nodes {\n        id\n        lowestCostSummary(merchantId: $merchantId) {\n          id\n          termLength\n          recurring {\n            ...VariantCostSummaryRecurringFields\n          }\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n',
): (typeof documents)['\n  query ProductsRepoGetPaginatedProductLowestCostSummaries($merchantId: ID!, $first: Int, $after: String) {\n    productsConnection(forCheckoutInput: { onlyAvailable: false }, merchantId: $merchantId, first: $first, after: $after) {\n      nodes {\n        id\n        lowestCostSummary(merchantId: $merchantId) {\n          id\n          termLength\n          recurring {\n            ...VariantCostSummaryRecurringFields\n          }\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL
 * clients.
 */
export function gql(
  source: '\n  query AccountIntercomQuery {\n  customer {\n    id\n    email\n    messagingDetails {\n      intercom {\n        appId\n        userHash\n        userId\n      }\n    }\n  }\n}',
): (typeof documents)['\n  query AccountIntercomQuery {\n  customer {\n    id\n    email\n    messagingDetails {\n      intercom {\n        appId\n        userHash\n        userId\n      }\n    }\n  }\n}'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL
 * clients.
 */
export function gql(
  source: '\n  query ProductsRepoProductCategories($merchantId: ID!) {\n    productCategories(merchantId: $merchantId) {\n      id\n      slug\n      displayName\n    }\n  }\n',
): (typeof documents)['\n  query ProductsRepoProductCategories($merchantId: ID!) {\n    productCategories(merchantId: $merchantId) {\n      id\n      slug\n      displayName\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL
 * clients.
 */
export function gql(
  source: '\n  query ProductsRepoVariantPricePlans($ids: [ID!], $merchantId: ID!) {\n    variants(forCheckout: true, merchantId: $merchantId, ids: $ids) {\n      id\n      costSummaries(merchantId: $merchantId) {\n        id\n        includesInsurance\n        termLength\n        recurring {\n          ...VariantCostSummaryRecurringFields\n        }\n        initial {\n          ...VariantCostSummaryInitialFields\n        }\n        feeSummary {\n          nonReturnFee {\n            formattedValue\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query ProductsRepoVariantPricePlans($ids: [ID!], $merchantId: ID!) {\n    variants(forCheckout: true, merchantId: $merchantId, ids: $ids) {\n      id\n      costSummaries(merchantId: $merchantId) {\n        id\n        includesInsurance\n        termLength\n        recurring {\n          ...VariantCostSummaryRecurringFields\n        }\n        initial {\n          ...VariantCostSummaryInitialFields\n        }\n        feeSummary {\n          nonReturnFee {\n            formattedValue\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL
 * clients.
 */
export function gql(
  source: '\n  query ProductsRepoProductVariantConditions($productCategoryIds: [ID!], $merchantId: ID!) {\n    products(forCheckout: true, productCategoryIds: $productCategoryIds, merchantId: $merchantId) {\n      id\n      variants(forCheckout: true, merchantId: $merchantId) {\n        id\n        condition\n      }\n    }\n  }\n',
): (typeof documents)['\n  query ProductsRepoProductVariantConditions($productCategoryIds: [ID!], $merchantId: ID!) {\n    products(forCheckout: true, productCategoryIds: $productCategoryIds, merchantId: $merchantId) {\n      id\n      variants(forCheckout: true, merchantId: $merchantId) {\n        id\n        condition\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL
 * clients.
 */
export function gql(
  source: '\n  query ProductsRepoProductVariants($ids: [ID!], $merchantId: ID!) {\n    products(forCheckoutInput: { onlyAvailable: false }, ids: $ids, merchantId: $merchantId) {\n      id\n      canAddTradeIn\n      displayName\n      specifications\n      keyFeatures\n      optionTypes {\n        id\n        slug\n        displayName\n        position\n      }\n      category {\n        id\n        displayName\n      }\n      manufacturer {\n        id\n        displayName\n      }\n      overview\n      variants(forCheckoutInput: { onlyAvailable: false }, merchantId: $merchantId) {\n        id\n        displayName\n        slug\n        subtitle\n        specifications\n        position\n        available(merchantId: $merchantId)\n        condition\n        images {\n          alt\n          position\n          tag\n          title\n          url\n        }\n        optionValues {\n          id\n          displayName\n          raw\n          position\n          optionType {\n            id\n            slug\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query ProductsRepoProductVariants($ids: [ID!], $merchantId: ID!) {\n    products(forCheckoutInput: { onlyAvailable: false }, ids: $ids, merchantId: $merchantId) {\n      id\n      canAddTradeIn\n      displayName\n      specifications\n      keyFeatures\n      optionTypes {\n        id\n        slug\n        displayName\n        position\n      }\n      category {\n        id\n        displayName\n      }\n      manufacturer {\n        id\n        displayName\n      }\n      overview\n      variants(forCheckoutInput: { onlyAvailable: false }, merchantId: $merchantId) {\n        id\n        displayName\n        slug\n        subtitle\n        specifications\n        position\n        available(merchantId: $merchantId)\n        condition\n        images {\n          alt\n          position\n          tag\n          title\n          url\n        }\n        optionValues {\n          id\n          displayName\n          raw\n          position\n          optionType {\n            id\n            slug\n          }\n        }\n      }\n    }\n  }\n'];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> =
  TDocumentNode extends DocumentNode<infer TType, any> ? TType : never;
