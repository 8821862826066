export const aboutSubMenuLinks = [
  {
    displayName: 'About us',
    link: 'https://www.raylo.com/about',
  },
  {
    displayName: 'Blog',
    link: 'https://www.raylo.com/blog',
  },
  {
    displayName: 'Sustainability',
    link: 'https://www.raylo.com/sustainability',
  },
  {
    displayName: 'Careers',
    link: 'https://www.raylo.com/careers',
  },
];

export const helpSubMenuLinks = [
  {
    displayName: 'Help centre',
    link: 'https://help.raylo.com/',
  },
  {
    displayName: 'Contact us',
    link: 'https://help.raylo.com/en/articles/3271547-contact-raylo',
  },
];

export const categoryForUrl = (category: string) => {
  return `/products?category=${category.toLowerCase()}`;
};
