import { palette } from '@raylo-tech/raylopay-ui';

export const productIndexPage = {
  catDisplayCard: {
    backgroundColour: palette.charcoal[200],
  },
  button: {
    hover: {
      backgroundColour: 'none',
    },
  },
};
